// Telr Gateway Component

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { stringify } from "@angular/compiler/src/util";
import { ActivatedRoute, Router } from "@angular/router";

import { ApiService } from "../services/api.service";
import { LinkValidity } from "../models/link-validity";
import { TransactionDetails } from "../models/transaction-details";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import * as moment from "moment";

@Component({
  selector: "app-terms-page",
  templateUrl: "./terms-page.component.html",
  styleUrls: ["./terms-page.component.css"]
})
export class TermsConditionsComponent implements OnInit {
  gateway: string;
  idf = "";

  constructor(
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: Ng4LoadingSpinnerService
  ) {
    this.spinner.show();
    this.spinner.hide();
    this.route.queryParams.subscribe(
      params => {
        this.idf = params.idf;
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  ngOnInit() {}

  navigate() {
    this.router.navigate(["t"]);
  }
}
