import { NiFeedbackPageComponent } from './ni-feedback-page/ni-feedback-page.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PaymentPageComponent } from './payment-page/payment-page.component'
import { SuccessPageComponent } from './success-page/success-page.component';
import { FailurePageComponent } from './failure-page/failure-page.component';
import { TermsConditionsComponent } from './terms-page/terms-page.component';
import { PrivacyPolicyComponent } from './privacy-policy-page/privacy-policy.component';

export const routes: Routes = [
  { path: '', component: PaymentPageComponent, pathMatch: 'prefix' },
  { path: 't', component: TermsConditionsComponent, pathMatch: 'prefix' },
  { path: 'p', component: PrivacyPolicyComponent, pathMatch: 'prefix' },
  { path: 'a', component: SuccessPageComponent, pathMatch:'prefix' },
  { path: 'd' , component: FailurePageComponent,pathMatch:'prefix'},
  { path: 'ni' , component: NiFeedbackPageComponent,pathMatch:'prefix'},
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false });