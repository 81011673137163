import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '../services/api.service';
import { CompletedTransaction } from '../models/completed-transaction';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'
import * as moment from 'moment'

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit {

  successTxn: CompletedTransaction;
  txnDate;
  txnDetailsAvailable = false;
  idf;
  constructor(private route: ActivatedRoute, private apiService: ApiService, private spinner: Ng4LoadingSpinnerService) {
    this.spinner.show()
    this.route.queryParams.subscribe(params => {
      this.idf = params.i;
      // http://paymenttst.awrostamani.ae/a?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cm4iOiJOQzY3OTgyNiIsIm9ybiI6IjEzNTgiLCJzIjoiYSIsImlhdCI6MTU1MTc2NTI0MH0.IEsSHBcri30h0iZocaVXwc-E-hMLsncaGnw7Oxrd-8Y
      this.apiService.updatePay(params.t, 'Telr', 'telr/update').subscribe(res => {
        var txn: any = res;
        this.successTxn = txn[0];
        this.txnDate = moment(this.successTxn.txnDate).format('D MMMM YYYY')
        this.spinner.hide();
        this.txnDetailsAvailable = true;
      },
        err => {
          this.spinner.hide();
        });
    });

  }

  ngOnInit() {
  }

}
