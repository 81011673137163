import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { CompletedTransaction } from '../models/completed-transaction';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-ni-feedback-page',
  templateUrl: './ni-feedback-page.component.html',
  styleUrls: ['./ni-feedback-page.component.css']
})
export class NiFeedbackPageComponent implements OnInit {
  retryURL;
  txnDate;
  txnDetailsAvailable = false;
  payGt;
  idf;
  transaction: CompletedTransaction;
  constructor(private route: ActivatedRoute, private apiService: ApiService, private spinner: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.payGt = params.p.toLowerCase();
      this.idf = params.i;
      this.apiService.updatePay(params.t, this.payGt, this.payGt + '/update')
      .subscribe(res => {
        let txn: any = res;
        this.transaction = txn[0];
        this.txnDate = moment(this.transaction.txnDate).format('D MMMM YYYY');
        // this.retryURL = environment.viewURL.concat('?custAccID=').concat(this.failedTxn.customerId).concat('&txnID=').concat(this.failedTxn.txnId).concat('&txnType=').concat(this.failedTxn.linkTxnType).concat('&idf=AAC&mobLinkID=1&payGt=Telr&tstamp=').concat(this.failedTxn.linkTimestamp);
        // tslint:disable-next-line:max-line-length
        this.retryURL = environment.viewURL + '?custAccID='+ this.transaction.customerId + '&txnID='+ this.transaction.txnId + '&txnType='+ this.transaction.linkTxnType + '&idf=' + this.idf + '&mobLinkID=1&payGt=' + params.p + '&tstamp=' + this.transaction.linkTimestamp;
        this.spinner.hide();
        this.transaction['bgcolor'] = this.transaction['status'] === 'CAPTURED' ? 'green' : 'red';
        this.txnDetailsAvailable = true;
      },
        err => {
          this.spinner.hide();
        });
    });
  }

  goToPayment() {
    window.location.href = this.retryURL;
  }

}
