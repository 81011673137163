import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ApiService } from './services/api.service';
import { FailurePageComponent } from './failure-page/failure-page.component';
import { NiFeedbackPageComponent } from './ni-feedback-page/ni-feedback-page.component';
import { TermsConditionsComponent } from './terms-page/terms-page.component';
import { PrivacyPolicyComponent } from './privacy-policy-page/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    PaymentPageComponent,
    SuccessPageComponent,
    FailurePageComponent,
    NiFeedbackPageComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    routing,
    Ng4LoadingSpinnerModule.forRoot()
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {}
