import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { LinkValidity } from '../models/link-validity';
import { TransactionDetails } from '../models/transaction-details';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  validatePaylink(custAccountID: string, txnID: string, txnSourceType: string, identifier: string, mobLinkID: string, attribute1?: string, attribute2?: string) {
    const urlParams = new HttpParams().set('custAccountID', custAccountID).set('txnID', txnID).set('txnSourceType', txnSourceType).set('identifier', identifier).set('mobLinkID', mobLinkID).set('attribute1', attribute1).set('attribute2', attribute2);

    const reqHeaders = new HttpHeaders({
      'auth':'WELCOME$1',
      'appName':'AWRPAY2018',
    });
    const httpOptions = {
      headers: reqHeaders,
      params: urlParams
    }
    return this.http.get(environment.AWRApiEndpoint.concat('ValidateOnlinePaylink'), httpOptions);    
  }

  getTransactionDetails(txnRefNum: string, txnType: string, custAccID: string,attribute4: string) {
    const urlParams = new HttpParams();
    const reqHeaders = new HttpHeaders({
      'auth': 'WELCOME$1',
      'appName': 'AWRPAY2018',
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: reqHeaders,
      params: urlParams
    }
    var body = {
      "p_source": "AWR_PAYMENT_APP",
      "p_enrich_id": "",
      "transactionRecord": {
        "payment_option": null,
        "txn_type": txnType,
        "txn_ref_number": txnRefNum,
        "txn_date_from": moment().format('YYYY-MM-DD'),
        "txn_date_to": moment().format('YYYY-MM-DD'),
        "plate_code": null,
        "all_txn_flag": null,
        "attribute1": custAccID,
        "attribute2": null,
        "attribute3": null,
        "attribute4": attribute4,
        "attribute5": null
      }
    }
    return this.http.put(environment.AWRApiEndpoint.concat('Transaction/GetTransactionDetailsV1'),body,httpOptions);    
  }

  getReceiptNumber(txnDetails: TransactionDetails, gatewayAmt: number, rewardPtsValue?: number, enrichRatio?: number) {
    const urlParams = new HttpParams();
    const reqHeaders = new HttpHeaders({
      'auth': 'WELCOME$1',
      'appName': 'AWRPAY2018',
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: reqHeaders,
      params: urlParams
    }
    var rewardPtsRedeemed = rewardPtsValue * enrichRatio
    var body = {
      "enrichID": "",
      "paymentRecord": {
        "paymentOption": "MAKE_PAYMENT",
        "paymentDate": moment().format('YYYY-MM-DD kk:mm:ss.S'),
        "paymentReference": txnDetails.txn_number,
        "paymentId": "-1",
        "amount": gatewayAmt.toString(),
        "paymentSource": "AWR_PAYMENT_WEBPAGE",
        "pointsRedeemed": (rewardPtsRedeemed || 0).toString(),
        "pointsRedeemedValue": (rewardPtsValue || 0).toString(),
        "redeemTxnId": "0",
        "txnType": txnDetails.txn_type,
        "cardNumber": null,
        "attribute1": null,
        "attribute2": null,
        "attribute3": null,
        "attribute4": null,
        "attribute5": null
      },
      "selectedTransactionList": [
        {
          "paymentId": "0",
          "txnType": txnDetails.txn_type,
          "txnId": txnDetails.txn_id,
          "custAccountId": txnDetails.cust_account_id,
          "sourceRefId": "0",
          "amountPaid": txnDetails.txn_amount,
          "pointsPurchased": "0",
          "pointsPurchasedValue": "0",
          "pointsPurchaseTxnId": "0",
          "attribute1": null,
          "attribute2": null,
          "attribute3": null,
          "attribute4": null,
          "attribute5": null
        }
      ]
    }
    return this.http.put(environment.AWRApiEndpoint.concat('CreateUpdateReceipt'),body,httpOptions);    
  }

  getPayLink(linkDetails: LinkValidity, txnDetails: TransactionDetails, gatewayAmt: number, oracleReceiptNum: string,
     tstamp: number, txnType: string, payGt: string, identifier: string,
     link, rewardPtsValue?: number, enrichRatio?: number) {

    const urlParams = new HttpParams();
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: reqHeaders,
      params: urlParams
    };
    var rewardPtsRedeemed = rewardPtsValue * enrichRatio;
    var body = {
      customerFirstName: linkDetails.firstName,
      customerLastName: linkDetails.lastName,
      customerAddress1: linkDetails.billAddress1,
      customerCity: linkDetails.billCity,
      customerCountry: linkDetails.billCountry,
      customerEmail: linkDetails.billEmail,
      customerMobile: linkDetails.billMobile,
      customerId: txnDetails.cust_account_id,
      txnId: txnDetails.txn_id,
      txnRefNum: txnDetails.txn_number,
      txnType: txnDetails.txn_type,
      oracleReceiptNum: oracleReceiptNum,
      billAmt: txnDetails.txn_amount,
      gatewayAmt: gatewayAmt,
      txnDate: txnDetails.txn_date,
      rewardPtsRedeemed: (rewardPtsRedeemed || 0),
      rewardPtsValue: (rewardPtsValue || 0),
      enrichConversionRatio: (enrichRatio || 0),
      linkTimestamp: tstamp.toString(),
      linkTxnType: txnType,
      identifier: identifier,
      payGt: payGt
    }

    return this.http.post(environment.apiEndpoint.concat(link), body, httpOptions);
  }

  updatePay(token: string, payGt, link) {
    const urlParams = new HttpParams();
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: reqHeaders,
      params: urlParams
    };
    const body = {
      token: token
    };

    return this.http.post(environment.apiEndpoint.concat(link), body, httpOptions);
  }

  private extractData(res: Response) {
    return res.json() || {};
  }
  private handleError(error: Response | any) {

  }
}
