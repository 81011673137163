import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '../services/api.service';
import { CompletedTransaction } from '../models/completed-transaction';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-failure-page',
  templateUrl: './failure-page.component.html',
  styleUrls: ['./failure-page.component.css']
})
export class FailurePageComponent implements OnInit {

  failedTxn: CompletedTransaction;
  retryURL;
  txnDate;
  txnDetailsAvailable = false;
  idf;
  constructor(private route: ActivatedRoute, private apiService: ApiService, private spinner: Ng4LoadingSpinnerService) {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.idf = params.i;

      this.apiService.updatePay(params.t, 'Telr', 'telr/update').subscribe(res => {
        var txn: any = res;
        this.failedTxn = txn[0];
        this.txnDate = moment(this.failedTxn.txnDate).format('D MMMM YYYY')
        // this.retryURL = environment.viewURL.concat('?custAccID=').concat(this.failedTxn.customerId).concat('&txnID=').concat(this.failedTxn.txnId).concat('&txnType=').concat(this.failedTxn.linkTxnType).concat('&idf=AAC&mobLinkID=1&payGt=Telr&tstamp=').concat(this.failedTxn.linkTimestamp);
        // tslint:disable-next-line:max-line-length
        this.retryURL = environment.viewURL + '?custAccID=' + this.failedTxn.customerId + '&txnID=' + this.failedTxn.txnId + '&txnType=' + this.failedTxn.linkTxnType + '&idf=' + this.idf + '&mobLinkID=1&payGt=Telr&tstamp=' + this.failedTxn.linkTimestamp;

        this.spinner.hide();
        this.txnDetailsAvailable = true;
      },
        err => {
          this.spinner.hide();
        });
    });
  }

  ngOnInit() {
  }

  goToPayment() {
    window.location.href = this.retryURL;
  }
}
