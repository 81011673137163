// Telr Gateway Component

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { stringify } from '@angular/compiler/src/util';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { LinkValidity } from '../models/link-validity';
import { TransactionDetails } from '../models/transaction-details';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})

export class PaymentPageComponent implements OnInit {
  gateway: string
  rewardForm: FormGroup
  payableForm: FormGroup
  isLinkValid = -2;
  billAmt
  rewardPtsBalance
  rewardPtsValue
  gatewayAmt
  isRedeemed
  enrichRatio = 0;
  placeholder
  linkDetails: LinkValidity
  txnDetails: TransactionDetails
  txnDate
  linkExpiryDuration
  linkTimestamp
  linkTxnType
  canEdit = false
  payGt: string;
  idf = '';
  constructor(public formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private apiService: ApiService, private spinner: Ng4LoadingSpinnerService) {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.linkTimestamp = params.tstamp;
      //this.payGt = params.payGt.toLowerCase();
      this.payGt = params.payGt!=null?params.payGt.toLowerCase():params.payGt;
      this.idf = params.idf;
      this.linkTxnType = params.txnType;
      if (Object.keys(params).length < 7) {
        this.isLinkValid = 0;
        this.spinner.hide();
      }
      else if(params.payGt.toLowerCase() === 'telr' || params.payGt.toLowerCase() === 'ni'){
        this.apiService.validatePaylink(params.custAccID, params.txnID, params.txnType, params.idf, params.mobLinkID, params.attribute1, params.attribute2).subscribe(res => {
          const validity: any = res;
          console.log(res);
          if (validity.retCode == 'S') {
            this.linkDetails = validity;
            console.log(this.linkDetails);
            this.linkExpiryDuration = (parseFloat(this.linkDetails.attribute5) || 0) * 24 * 60 * 60 * 1000;
            // this.linkExpiryDuration = 1000 * 24 * 60 * 60 * 1000;
            if ((Date.now() - this.linkTimestamp) > (this.linkExpiryDuration)) {
              this.isLinkValid = -1;
              this.spinner.hide()
            }
            else {
              this.apiService.getTransactionDetails(this.linkDetails.attribute4, this.linkDetails.attribute3, params.custAccID,params.txnID).subscribe(res => {
                var details: any = res;
                console.log(details);
                if (details.x_ret_code == 'S') {
                  this.txnDetails = details.x_txn_list[0];
                  this.billAmt = parseFloat(this.txnDetails.balance_amount)
                  if (this.linkDetails.attribute2 == 'Y') {
                    this.payableForm = formBuilder.group({
                      payableAmt: [{ value: this.billAmt, disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]+[\.]{0,1}[0-9]{0,2}$'), Validators.min(0)])],
                    });
                    this.canEdit = true;
                  }
                  else if (this.linkDetails.attribute2 == 'D') {
                    this.payableForm = formBuilder.group({
                      payableAmt: [{ value: this.billAmt, disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]+[\.]{0,1}[0-9]{0,2}$'), Validators.min(0), Validators.max(this.billAmt)])],
                    });
                    this.canEdit = true;
                  }
                  else if (this.linkDetails.attribute2 == 'I') {
                    this.payableForm = formBuilder.group({
                      payableAmt: [{ value: this.billAmt, disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]+[\.]{0,1}[0-9]{0,2}$'), Validators.min(this.billAmt)])],
                    });
                    this.canEdit = true;
                  }
                  else if (this.linkDetails.attribute2 == 'N') {
                    this.payableForm = formBuilder.group({
                      payableAmt: [{ value: this.billAmt, disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]+[\.]{0,1}[0-9]{0,2}$'), Validators.min(this.billAmt), Validators.max(this.billAmt)])],
                    });
                    this.canEdit = false;
                  }
                  this.txnDate = moment(this.txnDetails.txn_date).format('D MMMM YYYY')
                  this.isLinkValid = 1;
                  this.spinner.hide()
                }
                else {
                  this.isLinkValid = 0;
                  this.spinner.hide()
                }
              })
              if (this.linkDetails.loyaltyEnabled == 'Y') {
                this.rewardPtsBalance = parseFloat(this.linkDetails.loyaltyPoints);
                this.enrichRatio = parseFloat(this.linkDetails.attribute1);
                this.rewardForm = formBuilder.group({
                  rewardPtsRedeemed: [0, Validators.compose([Validators.required, Validators.pattern('[1-9]+[0-9]*00'), Validators.min(1), Validators.max(this.rewardPtsBalance)])]
                });
                this.placeholder = "1-" + stringify(this.rewardPtsBalance)
              }
            }

          }
          else {
            this.isLinkValid = 0;
            this.spinner.hide()
          }
        })
      } else {
        this.isLinkValid = 0;
        this.spinner.hide();
      }

    },
      err => {
        this.isLinkValid = 0;
        this.spinner.hide()
      }

    );
  }

  ngOnInit() {
  }

  edit() {
    this.payableForm.controls['payableAmt'].enable();
  }

  navigateToTerms() {
    this.router.navigate(["t"],{ queryParams: { idf: this.idf } });
  }
  navigateToPrivacyPolicy() {
    this.router.navigate(["p"], { queryParams: { idf: this.idf } });
  }

  redeemPoints(e) {
    this.isRedeemed = e;
  }

  makePayment() {
    this.spinner.show()
    if (this.isRedeemed) {
      this.rewardPtsValue = this.rewardForm.controls.rewardPtsRedeemed.value / this.enrichRatio
      this.gatewayAmt = this.payableForm.controls.payableAmt.value - this.rewardPtsValue;
      this.txnDetails.txn_amount = this.payableForm.controls.payableAmt.value.toString();
      this.apiService.getReceiptNumber(this.txnDetails, this.gatewayAmt, this.rewardPtsValue, this.enrichRatio).subscribe(res => {
        let response: any = res;
        if (response.retCode == 'S') {
          this.apiService.getPayLink(this.linkDetails, this.txnDetails, this.gatewayAmt, response.receiptNumber, this.linkTimestamp, this.linkTxnType, this.payGt, this.idf,
            this.payGt + '/create' ,this.rewardPtsValue, this.enrichRatio).subscribe(res => {
            let payLink: any = res;
            this.spinner.hide();
            if (this.payGt == 'telr') {
              window.location.href = payLink.order.url;
            } else if (this.payGt == 'ni') {
              window.location.href = payLink['_links'].payment.href;
            }
          });
        }
        if (response.retCode != 'S') {
          this.spinner.hide()
        }
      })
    }
    else {
      this.gatewayAmt = this.payableForm.controls.payableAmt.value;
      this.txnDetails.txn_amount = this.payableForm.controls.payableAmt.value.toString();
      this.apiService.getReceiptNumber(this.txnDetails, this.gatewayAmt).subscribe(res => {
        var response: any = res;
        if (response.retCode == 'S') {
          this.apiService.getPayLink(this.linkDetails, this.txnDetails, this.gatewayAmt, response.receiptNumber, this.linkTimestamp,
             this.linkTxnType, this.payGt, this.idf,this.payGt+'/create').subscribe(res => {
            var payLink: any = res;
            console.log(payLink);
            this.spinner.hide()
            if (this.payGt == 'telr') {
              window.location.href = payLink.order.url;
            } else if (this.payGt == 'ni') {
              window.location.href = payLink['_links'].payment.href;
            }
          })
        }
        if (response.retCode != 'S') {
          this.spinner.hide()
        }
      })
    }

  }

}
